import {Component, OnInit, ViewChild} from '@angular/core';
import {TokenStorageService} from "../token-storage.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {ApiService} from "../api.service";
import {map} from "rxjs/operators";
import {AuthResponse, AuthService, LoginForm, User} from "../auth/auth.service";
import {Observable} from "rxjs/internal/Observable";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {MatPaginator, MatSort, MatTableDataSource} from "@angular/material";
import * as moment from "moment";

export interface PeriodicElement {
    send_date: string;
    name: string;
    country_code: string;
    company: string;
    duplex:string;
    farbe:string;
    //types: string;
    adress: string;
    create_date: string;
    pages: number;
    status: string;
    blatt: string;
    porto: string;
    price: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    //{name: 'some name', company: 'some company', types: 'some types', adress: 'some adress', create_date: 'some create_date', send_date: 'send_date', pages: 5, status: 'some status'},
    //{name: 'some name', company: 'some company', types: 'some types', adress: 'some adress', create_date: 'some create_date', send_date: 'send_date', pages: 5, status: 'some status'},
    //{name: 'some name', company: 'some company', types: 'some types', adress: 'some adress', create_date: 'some create_date', send_date: 'send_date', pages: 5, status: 'some status'},
    //{name: 'some name', company: 'some company', types: 'some types', adress: 'some adress', create_date: 'some create_date', send_date: 'send_date', pages: 5, status: 'some status'},
];


@Component({
    selector: 'app-search-page',
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.css']
})

export class SearchPageComponent implements OnInit {
    displayedColumns: string[] = ['send_date',
                                    'name',
                                    'adress',
                                    'country_code',/*'company',*/
                                    'duplex',
                                    'farbe',/*'create_date',*/
                                    'pages',
                                    'blatt',
                                    'porto',
                                    'price',
                                    'status'];
    dataSource = new MatTableDataSource(ELEMENT_DATA);
    result = Object;
    form: FormGroup;
    documents = 0;
    searching = false;
    constructor(        private formBuilder:FormBuilder,
                        private router: Router,
                        private token:TokenStorageService,
                        private api:ApiService,
                        private authService:AuthService,
    ) { }
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngOnInit() {
        var check_token = this.token.checkToken();
        if (check_token){
        }
        else{
            this.router.navigate(['/auth']);
        }
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        this.form = this.formBuilder.group({
          picker_from: firstDay,
          picker_to: lastDay,
          query: '',
        })

        this.createTable();
  }

    createTable(data?) {
        if (data){
            this.dataSource = new MatTableDataSource(data);
        }
        else {
            this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        }
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    search(){
        this.searching = true;
        var g_query = this.form.value.query;
        this.authService.search(this.form.value).subscribe(
            (response)=>{
                var new_data:PeriodicElement[] = [];
                var num=0;
                if (response.GetDocumentReportResult != null) {
                    response.GetDocumentReportResult.forEach(function (value) {
                        var query = g_query;
                        var create_date = '';
                        if (value['CreatedDate'] != null) {
                            var some_date = value['CreatedDate'].substring(6, value['CreatedDate'].length - 7);
                            var create_date = new Date(some_date * 1).toString();
                            create_date = moment(create_date).format('DD.MM.YY hh:mm');
                        }
                        var sent_date = '';
                        if (value['SentDate'] != null) {
                            var some_date = value['SentDate'].substring(6, value['SentDate'].length - 7);
                            var sent_date = new Date(some_date * 1).toString();
                            sent_date = moment(sent_date).format('DD.MM.YY hh:mm');
                        }

                        var adress = "";
                        if (value['Address'] != null) {
                            if (value['Address']['Full'] != null) {
                                adress = value['Address']['Full'];
                            }
                        }

                        var porto = 'false.jpg';
                        var duplex = 'false.jpg';
                        var farbe = 'false.jpg';
                        value['DocumentOptions'].forEach(function (type_value) {
                            if (type_value['Name'] == "Duplex") {
                                if (type_value['Value'] == "True") {
                                    duplex = 'true.jpg';
                                }
                            }
                            if (type_value['Name'] == "Colored") {
                                if (type_value['Value'] == "True") {
                                    farbe = 'true.jpg';
                                }
                            }
                            if (type_value['Name'] == "Portooptimierung") {
                                if (type_value['Value'] == "True") {
                                    porto = 'true.jpg';
                                }
                            }
                        });

                        var country_code = "";
                        if (value['Address'] != null) {
                            if (value['Address']['CountryIso'] != null) {
                                country_code = value['Address']['CountryIso'];
                            }
                        }

                        var blatt = "";
                        if (value['PagesCount'] != null) {
                            if (duplex == "true.jpg") {
                                blatt = (Math.floor((value['PagesCount'] + 1) / 2)).toString();
                            }
                            else {
                                blatt = value['PagesCount'].toString();
                            }
                        }

                        var price = "";
                        if (value['Price'] != null) {
                            price = value['Price'];
                        }



                        var correct = true;
                        if (query != null){
                            query = query.trim();

                            if (adress.toLowerCase().includes(query.toLowerCase())){
                                correct = true;
                            }
                            else{
                                correct = false;
                            }
                        }

                        if (correct == true) {
                            new_data.push({
                                send_date: sent_date,
                                name: value['Username'],
                                company: value['CompanyName'],
                                duplex: duplex,
                                farbe: farbe,
                                adress: adress,
                                country_code: country_code,
                                create_date: create_date,
                                pages: value['PagesCount'],
                                blatt: blatt,
                                porto: porto,
                                price: price,
                                status: value['Status']
                            });
                            num++;
                        }
                    });
                }
                this.documents = Object.keys(new_data).length;
                this.createTable(new_data);
                this.searching = false;
            },
            (response:HttpErrorResponse)=>{
                this.searching = false;
            }
        );
    }

  clear(){
      this.form.reset();
  }

}
