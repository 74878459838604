import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {environment} from "../environments/environment"; 
import * as queryString from "query-string";

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient) {}

    private url(url: string) {
        return environment.apiHost + '/' + url;
    }
    private proxyUrl() {
        return environment.proxyUrl;
    }

    get(url: string, data?: object, some_headers?: object): Observable < any > {
        if (Object.keys(data).length > 0) {
            url += '?' + queryString.stringify(data);
        }
        var headers = new HttpHeaders();
        for (let key in some_headers) {
            let value = some_headers[key];
            headers = headers.set(key, value);
        }
        return this.http.get(this.proxyUrl()+this.url(url), {headers});
    }

    post(url: string, data?: object, some_headers?: object): Observable < any > {
        var headers = new HttpHeaders();
        for (let key in some_headers) {
            let value = some_headers[key];
            headers = headers.set(key, value);
        }
        return this.http.post(this.proxyUrl()+this.url(url), data,{headers});
    }

    delete(url: string): Observable < any > {
        return this.http.delete(this.url(url));
    }

    put(url: string, data: object): Observable < any > {
        return this.http.put(this.url(url), data);
    }

}