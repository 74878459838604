import {AbstractControl, FormBuilder} from '@angular/forms';
import {TokenStorageService} from "../token-storage.service";
import CryptoJS from 'crypto-js';
import {OnInit} from "@angular/core";


export class PasswordValidation {
    static MatchPassword(AC: AbstractControl) {
        let newpassword = AC.get('new_password').value; // to get value in input tag
        let confirmPassword = AC.get('repeat_new_password').value; // to get value in input tag

        let old_password  = AC.get('old_password').value;
        var utf8arr = CryptoJS.enc.Utf16LE.parse(old_password);
        var hash = CryptoJS.SHA256(utf8arr);
        var password = CryptoJS.enc.Base64.stringify(hash);

        var token = new TokenStorageService;
        var old_authorization =  token.getAuth();
        var new_authorization =  "Basic "+ btoa(token.getLoginName() + ":" + password);

        if(old_authorization != new_authorization) {
            AC.get('old_password').setErrors( {MatchPassword: true} )
        }

        if(newpassword != confirmPassword) {
            AC.get('repeat_new_password').setErrors( {MatchPassword: true} )
        } else {
            return null
        }
    }
}