import {Component, OnInit, ViewChild} from '@angular/core';
import {TokenStorageService} from "../token-storage.service";
import {FormBuilder, FormGroup, FormControl, Validators, ValidatorFn, AbstractControl} from "@angular/forms";
import {Router} from "@angular/router";
import {ApiService} from "../api.service";
import {MatPaginator, MatSort, MatTableDataSource, MatTab} from "@angular/material";
import CryptoJS from 'crypto-js';
import {AuthService} from "../auth/auth.service";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import { PasswordValidation } from './password-validation';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.css']
})

export class UserPageComponent implements OnInit {
    public form: FormGroup;
    public passwordform: FormGroup;
    public Validators: Validators;
    public AbstractControl: AbstractControl;

    old_email = "";
    searching = false;
    changing = false;

    constructor(        private formBuilder:FormBuilder,
                        private router: Router,
                        private token:TokenStorageService,
                        private api:ApiService,
                        private authService:AuthService,
    ) { }

    ngOnInit() {
        var check_token = this.token.checkToken();
        if (check_token){
        }
        else{
            this.router.navigate(['/auth']);
        }

        this.form = this.formBuilder.group({
            old_email: new FormControl({ value: this.old_email, disabled: true }),
            new_email: ['', [Validators.email, Validators.required]]
        })

        this.passwordform = this.formBuilder.group({
              old_password: ['', [Validators.required]],
              new_password: ['', [Validators.required]],
              repeat_new_password: ['', Validators.required]
            },
            {
            validator: PasswordValidation.MatchPassword // your validation method
            })

        this.searching = true;

        this.authService.userinfo().subscribe(
            (response)=>{
                if (response.GetUserInfoResult != null) {
                    this.form.patchValue({
                        old_email: response.GetUserInfoResult['Email']
                    });
                    this.searching = false;
                }
            },
            (response:HttpErrorResponse)=>{
                this.searching = false;
            }
        );
    }

    changeemail(){
        if (this.form.status == "VALID") {
            this.changing = true;
            this.authService.changeemail(this.form.value).subscribe(
                (response) => {
                    if (response.SetUserEmailResult != null) {
                        if (response.SetUserEmailResult['Result'] == true) {
                            this.form.patchValue({
                                old_email: this.form.value.new_email
                            });
                        }
                    }
                    this.changing = false;
                },
                (response: HttpErrorResponse) => {
                    this.changing = false;
                }
            );
        }
    }

    changepassword(){
        if (this.passwordform.status == "VALID") {
            this.changing = true;
            this.authService.changepassword(this.passwordform.value).subscribe(
                (response) => {
                    if (response.SetUserPasswordResult != null) {
                        if (response.SetUserPasswordResult['Result'] == true) {
                            var utf8arr = CryptoJS.enc.Utf16LE.parse(this.passwordform.value.new_password);
                            var hash = CryptoJS.SHA256(utf8arr);
                            var password = CryptoJS.enc.Base64.stringify(hash);
                            var authorization =  "Basic "+ btoa(this.token.getLoginName() + ":" + password);
                            this.token.saveAuth(authorization);

                            this.passwordform.setValue({
                                old_password: '',
                                new_password: '',
                                repeat_new_password: '',
                            });
                            alert('password saved');
                        }
                    }
                    this.changing = false;
                },
                (response: HttpErrorResponse) => {
                    console.log(response);
                    this.changing = false;
                }
            );
        }
    }
}
