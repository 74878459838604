import {Routes} from "@angular/router";
import {HomePageComponent} from "./home-page/home-page.component";
import {SearchPageComponent} from "./search-page/search-page.component";
import {UserPageComponent} from "./user-page/user-page.component";

export const routes: Routes = [
    {path:'', component:HomePageComponent},
    {path:'search', component:SearchPageComponent},
    {path:'user', component:UserPageComponent},
    {path:'auth', loadChildren:'./auth/auth.module#AuthModule'}
];