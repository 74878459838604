import {Component, HostListener} from '@angular/core';
import {TokenStorageService} from "./token-storage.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'Login';
    router_link = 'auth';
    check_token = false;
    is_top = true;
    is_login = false;

    @HostListener("window:scroll", [])
    onWindowScroll() {
        if(window.pageYOffset == 0){
            this.is_top = true;
        }
        else{
            this.is_top = false;
        }
        //we'll do some stuff here when the window is scrolled
    }

    constructor(private token:TokenStorageService,private router: Router,) {
    }



    ngOnInit() {
        this.router.events.subscribe((val)  => setTimeout(()  => {
            this.check_token = this.token.checkToken();
            if (this.check_token){
                this.is_login = true;
                var name = this.token.getLoginName();
                this.title = name + " | Logout";
                this.router_link = "auth/logout";
            }
            else{
                this.is_login = false;
                this.title = "Login";
                this.router_link = "auth";
            }
        }));
    }
}
