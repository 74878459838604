import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";
import {Observable} from "rxjs/internal/Observable";
import CryptoJS from 'crypto-js';
import {map} from "rxjs/operators";
import {TokenStorageService} from "../token-storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private api:ApiService,
              private token:TokenStorageService) { }

  register(registerForm:RegisterForm):Observable<any>{
      var emptyObject = {
          "companyInfo": {},
          "userInfo": {}
      };

      emptyObject['companyInfo']['Name'] = registerForm['Name'];
      emptyObject['companyInfo']['NameShort'] = registerForm['NameShort'];
      emptyObject['companyInfo']['Description'] = registerForm['Description'];
      emptyObject['companyInfo']['AddressAddon'] = registerForm['AddressAddon'];
      emptyObject['companyInfo']['StreetName'] = registerForm['StreetName'];
      emptyObject['companyInfo']['HouseNumber'] = registerForm['HouseNumber'];
      emptyObject['companyInfo']['PostOfficeBox'] = registerForm['PostOfficeBox'];
      emptyObject['companyInfo']['ZipCode'] = registerForm['ZipCode'];
      emptyObject['companyInfo']['City'] = registerForm['City'];

      emptyObject['userInfo']['Username'] = registerForm['Username'];
      emptyObject['userInfo']['Password'] = registerForm['Password'];
      emptyObject['userInfo']['Email'] = registerForm['Email'];

      emptyObject['voucher'] = registerForm['voucher'];


      return this.api.post("onboardCompany", emptyObject).pipe(map((res) => { return res }))
  }

  login(loginForm:LoginForm):Observable<AuthResponse>{
      var utf8arr = CryptoJS.enc.Utf16LE.parse(loginForm["password"]);
      var hash = CryptoJS.SHA256(utf8arr);
      var password = CryptoJS.enc.Base64.stringify(hash);

      var authorization =  "Basic "+ btoa(loginForm["email"] + ":" + password);
      this.token.saveAuth(authorization);
      var some_headers = {
          "Content-Type" : "application/json",
          "Authorization" : authorization,
      };
      var emptyObject = {};
      return this.api.get("checkLogin", emptyObject, some_headers).pipe(map(response=>response.data))
  }

    search(searchForm:SearchForm):Observable<any>{
        var authorization = this.token.getAuth();
        var some_headers = {
            "Content-Type" : "application/json",
            "Authorization" : authorization,
        };

        var emptyObject = {
            "filter": {}
        };

        var date_from = new Date(searchForm['picker_from']).getTime();
        var date_to= new Date(searchForm['picker_to']).getTime();
        var query = searchForm['query'];


        if (typeof date_from != undefined && date_from != null && date_from > 0){
            emptyObject['filter']['CreatedDateFrom'] = "/Date("+date_from+"+0200)/";
        }
        if (typeof date_to != undefined && date_to != null && date_to > 0){
            emptyObject['filter']['CreatedDateTo'] = "/Date("+date_to+"+0200)/";
        }

        /*if (typeof query != undefined && query != null && query != ""){
            emptyObject['filter']['Username'] = query.trim();
        }*/

        return this.api.post("getDocumentReport", emptyObject, some_headers).pipe(map((res) => { return res }))
    }

    userinfo():Observable<any>{
        var authorization = this.token.getAuth();
        var some_headers = {
            "Content-Type" : "application/json",
            "Authorization" : authorization,
        };

        var emptyObject = {
            "username": this.token.getLoginName()
        };

         return this.api.post("getUserInfo", emptyObject, some_headers).pipe(map((res) => { return res }))
    }

    changeemail(changeemailForm:changeemailForm):Observable<any>{
        var authorization = this.token.getAuth();
        var some_headers = {
            "Content-Type" : "application/json",
            "Authorization" : authorization,
        };

        var emptyObject = {
            "userInfo": {}
        };


        emptyObject['userInfo']['Email'] = changeemailForm['new_email'];
        emptyObject['userInfo']['Username']  = this.token.getLoginName();

        return this.api.post("setUserEmail", emptyObject, some_headers).pipe(map((res) => { return res }))
    }

    changepassword(changepasswordForm:changepasswordForm):Observable<any>{
        var authorization = this.token.getAuth();
        var some_headers = {
            "Content-Type" : "application/json",
            "Authorization" : authorization,
        };

        var emptyObject = {
            "userInfo": {}
        };
        var utf8arr = CryptoJS.enc.Utf16LE.parse(changepasswordForm['new_password']);
        var hash = CryptoJS.SHA256(utf8arr);
        var password = CryptoJS.enc.Base64.stringify(hash);
        emptyObject['userInfo']['Password'] = password;
        emptyObject['userInfo']['Username']  = this.token.getLoginName();

        return this.api.post("setUserPassword", emptyObject, some_headers).pipe(map((res) => { return res }))
    }
}
 
export interface ApiResponse<T> {
    data: T;
}
export interface LoginForm {
    email : string;
    password : string;
}

export interface RegisterForm {
    Name : string;
    NameShort : string;
    Description : string;
    AddressAddon : string;
    StreetName : string;
    HouseNumber : string;
    PostOfficeBox : string;
    ZipCode : string;
    City : string;
    Username : string;
    Password : string;
    Email : string;
    voucher : string;
}

export interface SearchForm {
    email : string;
    password : string;
}

export interface changeemailForm {
    new_email : string;
}

export interface changepasswordForm {
    new_password : string;
}



export interface AuthResponse {
    user: User;
}
export interface User {
    id: string;
    name: string;
    email: string;
}