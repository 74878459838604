import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {TokenStorageService} from "./token-storage.service";
import {filter, map, tap} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class AuthInerceptor implements HttpInterceptor{
    //private apiTokenName = 'Api-Token';
    private apiTokenName = 'date';

    constructor(private tokenStorage: TokenStorageService){}

    intercept(req:HttpRequest<any>,next:HttpHandler):Observable<HttpEvent<any>>{
        let token = this.tokenStorage.token();
        if (token){
            req.headers.append(`Bearer: ${this.apiTokenName}`, token);
        }
        return next.handle(req)
            .pipe(
                filter(response => response instanceof HttpResponse),
                tap((response:HttpResponse<any>)=>{
                    if (response.headers.get(this.apiTokenName)){
                        this.tokenStorage.setToken(response.headers.get(this.apiTokenName));
                    }
                })
            );
    }
}