import { Component, OnInit } from '@angular/core';
import {TokenStorageService} from "../token-storage.service";
import CryptoJS from 'crypto-js'
import {Router} from "@angular/router";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor(private token:TokenStorageService,private router: Router,) { }

  ngOnInit() {
      var check_token = this.token.checkToken();
      if (check_token){
      }
      else{
          this.router.navigate(['/auth']);
      }
 }

}
