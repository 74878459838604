import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js'

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }
  saveAuth(token:string){
      localStorage.setItem('auth-token', token);
  }
  getAuth(){
      return localStorage.getItem('auth-token');
  }
  deleteAuth(){
        localStorage.removeItem('auth-token');
    }

  setToken(token:string){
    localStorage.setItem('api-token', token);
  }
  deleteToken(){
    localStorage.removeItem('api-token');
  }
    setLoginName(name:string){
        localStorage.setItem('login-name', name);
    }
    getLoginName(){
        return localStorage.getItem('login-name');
    }

    deleteLoginName(){
        localStorage.removeItem('login-name');
    }

  token(){
    return localStorage.getItem('api-token');
  }
  checkToken(){
    var token = this.token();
    if (token) {
        var bytes = CryptoJS.AES.decrypt(token, 'siecom');
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        var current_date = new Date();
        var current_timestamp = current_date.getTime().toString();
        var millisec = parseInt(current_timestamp) - parseInt(originalText);
        var hours = parseInt((millisec / (1000 * 60 * 60)).toFixed(1));
        if (hours >= 8){
            return false;
        }
        else{
            var name = this.getLoginName();
            if (name == null){
                return false;
            }
            else {
                return true;
            }
        }
    }
    else {
        return false;
    }
  }

}
