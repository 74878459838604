import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { routes } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatCardTitle, MatDatepickerModule, MatFormFieldModule,
    MatIconModule, MatInputModule, MatNativeDateModule, MatPaginatorModule, MatSortModule, MatTableModule,
    MatToolbarModule
} from "@angular/material";
import {RouterModule} from "@angular/router";
import { HomePageComponent } from './home-page/home-page.component';
import {ApiService} from "./api.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {TokenStorageService} from "./token-storage.service";
import {AuthInerceptor} from "./auth.interceptor";
import { SearchPageComponent } from './search-page/search-page.component';
import {ReactiveFormsModule} from "@angular/forms";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { UserPageComponent } from './user-page/user-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    SearchPageComponent,
    UserPageComponent,
  ],
  imports: [
      BrowserModule,
      RouterModule.forRoot(routes, /*{ useHash: true }*/),
      BrowserAnimationsModule,
      HttpClientModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatToolbarModule,
      MatIconModule,
      MatCardModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatInputModule,
      MatTabsModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule
  ],
  providers: [
      ApiService,
      TokenStorageService,
      MatDatepickerModule,
      {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInerceptor,
          multi: true
      },
      /*{
          provide: LocationStrategy,
          useClass: HashLocationStrategy
      }*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
